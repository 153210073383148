import React, { useState, useEffect } from "react"
import { useStoryblokState } from "gatsby-source-storyblok"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import KnowledgeBanner from '../components/KnowledgeBanner'
import KnowledgeMain from '../components/KnowledgeMain'
import Seo from "components/Seo"

const KnowledgeHub = ({
  data: {
    knowledgeHub,
    knowledgeSections,
  },
  path,
}) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  let story = useStoryblokState(knowledgeHub, {
    resolveRelations: ["knowledgehub.author", "knowledgehub.sections"]
  });

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${knowledgeHub.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : story.content;

  return (
    <>
      <Seo
        title={knowledgeHub.name}
        image={content?.cover?.filename}
        url={knowledgeHub.path || knowledgeHub.slug}
      >
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "${knowledgeHub.name}",
            "image": [
              "${content.cover?.filename}"
            ],
            "datePublished": "${knowledgeHub.published_at}",
            "dateModified": "${knowledgeHub.modified_at}",
            "author": [{
              "@type": "Person",
              "name": "${content.author.name}",
              "url": "https://quickmail.com/authors/${content.author.slug}"
            }]
          }
        `}</script>
      </Seo>
      <KnowledgeBanner
        breadcrumbs={[{ name: content.nav_title || knowledgeHub.name, path: knowledgeHub.slug }]}
        heading={knowledgeHub.name}
        updatedAt={knowledgeHub.updatedAt}
        author={content.author}
        themeColor={content.banner_color}
      />
      <KnowledgeMain
        nav={{
          hub: story,
          allSections: knowledgeSections,
        }}
        data={content}
        content={content.content}
        themeColor={content.banner_color}
      />
    </>
  )
}

export default KnowledgeHub

export const KnowledgeHubQuery = graphql`
  query KnowledgeHubQuery($slug: String!) {
    knowledgeHub: storyblokEntry(slug: { eq: $slug }) {
      name
      slug
      path
      full_slug
      published_at: first_published_at
      modified_at: published_at
      updatedAt: published_at(formatString: "MMMM D, YYYY")
      content
    }
    knowledgeSections: allStoryblokEntry(
      filter: { field_component: { eq: "knowledgesection" } }
    ) {
      nodes {
        name
        slug
        content
      }
    }
  }
`
